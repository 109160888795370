import { createTheme } from '@material-ui/core/styles'
import createTypography from '@material-ui/core/styles/createTypography'
import { ToggleButtonGroupClassKey } from '@material-ui/lab/ToggleButtonGroup'
import { ToggleButtonClassKey } from '@material-ui/lab/ToggleButton'
import { alpha, lighten } from '@material-ui/core/styles/colorManipulator'

import palette from './palette'

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    customBg: any
    customCol: any
    blabu: any
  }

  interface PaletteOptions {
    customBg: any
    customCol: any
    blabu: any
  }
}

declare module '@material-ui/core/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiToggleButtonGroup: ToggleButtonGroupClassKey
    MuiToggleButton: ToggleButtonClassKey
  }
}

const fontSize = 16

export const pxToRem = size => `${size / fontSize}rem`

const theme = createTheme({
  palette,
})

const { breakpoints, spacing } = theme

theme.typography = createTypography(palette, {
  fontFamily: 'Mirai, sans-serif',
  fontSize,
  h1: {
    fontSize: pxToRem(56),
    fontWeight: 300,
    lineHeight: 1.143, // 64px
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(48),
    },
  },
  h2: {
    fontSize: pxToRem(48),
    fontWeight: 300,
    lineHeight: 1.167, // 56px
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(40),
    },
  },
  h3: {
    fontSize: pxToRem(40),
    fontWeight: 300,
    lineHeight: 1.2, // 48px
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(36),
    },
  },
  h4: {
    fontSize: pxToRem(36),
    fontWeight: 300,
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(32),
    },
  },
  h5: {
    fontSize: pxToRem(32),
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(24),
    },
  },
  h6: {
    fontSize: pxToRem(24),
    lineHeight: 1.333, // 32px
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(22),
    },
  },
  subtitle1: {
    fontSize: pxToRem(24),
    lineHeight: 1.5,
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(22),
    },
  },
  subtitle2: {
    fontSize: pxToRem(20),
    lineHeight: 1.2, // 24px
    [breakpoints.down('xs')]: {
      fontSize: pxToRem(16),
    },
  },
  body1: {
    fontSize: pxToRem(16),
  },
  body2: {
    fontSize: pxToRem(14),
  },
  button: {
    fontWeight: 600,
    lineHeight: 1.71,
    textTransform: 'none',
  },
  caption: {
    fontSize: pxToRem(12),
  },
  overline: {},
})

theme.overrides = {
  MuiButton: {
    /* Styles applied to the root element. */
    root: {
      fontSize: pxToRem(14),
      padding: '8px 16px',
    },
    /* Styles applied to the root element if `variant="text"`. */
    text: {
      padding: '8px 8px',
    },
    /* Styles applied to the root element if `variant="contained"`. */
    contained: {
      boxShadow: 'none',
    },
    /* Styles applied to the root element if `variant="outlined"`. */
    outlined: {
      borderWidth: 2,
      padding: '6px 15px',
      '&$disabled': {
        borderWidth: 2,
      },
    },
    /* Styles applied to the root element if `size="small"` and `variant="text"`. */
    textSizeSmall: {
      padding: '4px 5px',
      fontSize: pxToRem(14),
    },
    /* Styles applied to the root element if `size="large"` and `variant="text"`. */
    textSizeLarge: {
      padding: '12px 11px',
      fontSize: pxToRem(14),
    },
    /* Styles applied to the root element if `size="small"` and `variant="outlined"`. */
    outlinedSizeSmall: {
      padding: '2px 9px',
      fontSize: pxToRem(14),
    },
    /* Styles applied to the root element if `size="large"` and `variant="outlined"`. */
    outlinedSizeLarge: {
      padding: '10px 21px',
      fontSize: pxToRem(14),
    },
    /* Styles applied to the root element if `size="small"` and `variant="contained"`. */
    containedSizeSmall: {
      padding: '4px 10px',
      fontSize: pxToRem(14),
    },
    /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
    containedSizeLarge: {
      padding: '12px 22px',
      fontSize: pxToRem(14),
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="primary"`. */
    outlinedPrimary: {
      border: `2px solid ${theme.palette.primary.main}`,
      color: theme.palette.text.primary,
      '&:hover': {
        borderWidth: 2,
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
    outlinedSecondary: {
      borderWidth: 2,
      '&:hover, &$disabled': {
        borderWidth: 2,
      },
    },
    /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
    containedPrimary: {
      '&:hover': {
        backgroundColor: lighten(theme.palette.primary.main, 0.2),
      },
    },
  },
  MuiToggleButton: {
    root: {
      height: 'initial',
      color: palette.text.secondary,
      border: `1px solid ${palette.grey[600]}`,
      transition: 'all .3s',
      '&$selected': {
        color: palette.primary.main,
        backgroundColor: palette.primary.light,
        borderColor: palette.primary.main,
        '&:hover': {
          color: palette.primary.main,
          backgroundColor: palette.primary.light,
          borderColor: palette.primary.main,
        },
      },
      '&$disabled': {
        color: palette.action.disabled,
        backgroundColor: palette.action.disabledBackground,
        border: `1px solid ${palette.customCol.alto}`,
      },
      '&:first-child': {
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
      },
      '&:last-child': {
        borderTopRightRadius: 4,
        borderBottomRightRadius: 4,
      },
    },
  },
  MuiCheckbox: {
    root: {
      color: palette.action.disabled,
    },
  },
  MuiListItem: {
    root: {
      paddingBottom: spacing(1),
      paddingTop: spacing(1),
      '&$selected': {
        backgroundColor: 'transparent',
      },
    },
    button: {
      '&:hover': {
        [breakpoints.up('md')]: {
          backgroundColor: '#E0E8F9',
        },
      },
    },
    gutters: {
      paddingLeft: spacing(3),
      paddingRight: spacing(3),
    },
  },
  MuiAvatar: {
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
  MuiInputBase: {
    root: {
      backgroundColor: '#fff',
    },
    /* Styles applied to the `input` element. */
    input: {
      fontWeight: 600,
    },
  },
  MuiOutlinedInput: {
    /* Styles applied to the `input` element. */
    input: {
      padding: '18px 14px',
    },
    /* Styles applied to the root element if `multiline={true}`. */
    multiline: {
      padding: '18px 14px',
    },
  },
  MuiFormLabel: {
    /* Styles applied to the root element. */
    root: {
      '&$focused': {
        color: theme.palette.text.secondary,
      },
      '&$error': {
        color: theme.palette.text.secondary,
      },
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: 36,
    },
  },
  MuiDialog: {
    paperFullWidth: {
      width: '100%',
    },
  },
  MuiTableCell: {
    root: {
      borderBottom: `1px solid ${lighten(alpha(theme.palette.divider, 1), 0.58)}`,
    },
    head: {
      fontWeight: 600,
    },
  },
}

export default theme
