import createPalette from '@material-ui/core/styles/createPalette'

export default createPalette({
  primary: {
    light: '#FFFAEF',
    main: '#FFB400',
    dark: '#D29505',
  },
  secondary: {
    main: '#f40000',
  },
  error: {
    main: '#f40000',
  },
  text: {
    primary: '#000000',
    secondary: '#626261',
    hint: '#8C8C8C',
  },
  action: {
    disabled: '#91959E',
    disabledBackground: '#ECEDEF',
    hoverOpacity: 0.1,
  },
  background: {
    default: '#ffffff',
  },
  divider: '#CFD0E6',
  customBg: {
    success: '#E6FBDD',
    error: '#FFEDED',
    gossamer: '#49A48B',
    catskillWhite: '#E4E7EB',
    linkWater: '#E0E8F9',
    tequila: '#FFE7CC',
    zircon: '#F2F4FF',
    dawn: '#FFF7E6',
    alabaster: '#F7F7F7',
    hintGreen: '#E6FFF2',
    cosmos: '#FFCFCC',
    titan: '#FCFCFF',
    pippin: '#ffe6e6',
    aliceBlue: '#F7F9FB',
    beige: '#F9F2E5',
    stone: '#E8EAF2',
    butterMilk: '#FFF8E6',
  },
  customCol: {
    success: '#00b600',
    error: '#f40000',
    cornflowerBlue: '#D9D9FF',
    chablis: '#FFF2F2',
    sunglow: '#FFC933',
    shamrock: '#00b600',
    mountain: '#00b600',
    jungle: '#00b600',
    silver: '#BFBFBF',
    scorpion: '#5A5A5A',
    alto: '#DEDEDE',
    dustyGray: '#979797',
    mercury: '#E2E2E2',
    moody: '#707CCC',
    pearlLusta: '#FCF3E3', // blabu yellow
    persimmon: '#f40000', // blabu logo
    mexican: '#f40000',
    solitude: '#ECEDEF',
    manatee: '#91959E',
    shadow: '#222222',
    pewter: '#D2D2D2',
    skyblue: '#7184D4',
    cloud: '#F2F4F8',
    fog: '#F8F9FB',
    steel: '#C5C7CA',
    koala: '#B9BABB',
    dove: '#20387042',
  },
  blabu: {
    bg: '#fcf3e3',
    logo: '#fc5d4e',
  },
})
